import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button'


import Empaques1 from '../Assets/img/Empaques/Picture1.png'
import Empaques2 from '../Assets/img/Empaques/Picture2.png'
import Empaques3 from '../Assets/img/Empaques/Picture3.png'
import Empaques4 from '../Assets/img/Empaques/Picture4.png'
import Empaques5 from '../Assets/img/Empaques/Picture5.png'
import Empaques6 from '../Assets/img/Empaques/Picture6.png'
import Empaques7 from '../Assets/img/Empaques/Picture7.png'
import Empaques8 from '../Assets/img/Empaques/Picture8.png'
import Empaques9 from '../Assets/img/Empaques/Picture9.png'
import Empaques10 from '../Assets/img/Empaques/Picture10.png'
import Empaques11 from '../Assets/img/Empaques/Picture11.png'
import Empaques12 from '../Assets/img/Empaques/Picture12.png'
import Empaques13 from '../Assets/img/Empaques/Picture13.png'
import Empaques14 from '../Assets/img/Empaques/Picture14.png'





class Empaques extends Component {

    render() {
        return (
            <Container >
                            <Row>
<Col></Col>
<Col className='robotomono font-weight-bold pl-5'><h3 className='robotomono font-weight-bold ' > Biodegradables</h3></Col>
<Col></Col>


</Row>

            <Row className='montserrat font-weight-bold' >

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect border-0' src={Empaques1}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques2}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4} >
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques3}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques4} />
                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className=' zoom hovereffect' src={Empaques5} />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques6}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques7}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques8}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques9}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques10}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques11}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques12}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques13}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Empaques14}  />

                    </Button>
                </Col>

            </Row>
        </Container >




        );
    }
}

export default Empaques;


