import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Figure from 'react-bootstrap/Figure';
import '../CSS/fonts.css'




import { Link } from 'react-router-dom';

import logo from '../Assets/img/logo.png'
import insta from '../Assets/img/insta.png'
import face from '../Assets/img/face.png'


function Menu() {
    return (

        <Navbar variant="light" className='mx-3 my-3' bg="white" expand="lg">
                 
                <Figure className="justify-content-end mt-2">
                    <a href='https://www.mundoempaques.com'>
                        <Figure.Image width={100} height={100} src={logo} />
                    </a>
                </Figure><p> </p>
         
            <div>

                <h3 className='robotomono font-weight-bold ml-5 mb-3' href="#home"> Mundo Empaques</h3>

            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className=" justify-content-end p-2" id="basic-navbar-nav">
                <h5><Nav className='pb-4 robotomono font-weight-bold '>
                    <Link style={{ textDecoration: 'none' }} to={"../pages/Nosotros"} >
                        <Nav.Link href="Nosotros">Nosotros</Nav.Link>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={"/"}>
                        <Nav.Link href="#home">Productos</Nav.Link>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={"../pages/Contact"}>
                        <Nav.Link href="Contact">Contacto</Nav.Link>
                    </Link>

                </Nav></h5>
            </Navbar.Collapse>

            {/* IMDB */}
            <Nav >
                <Figure className="justify-content-end">
                    <a href='https://www.facebook.com/mundoempques'>
                        <Figure.Image width={55} height={55} src={face} />
                    </a>
                </Figure><p> </p>

                
            </Nav>
            {/* VIMEOs */}
            <Nav >
                <Figure className="justify-content-end">
                    <a href='https://www.instagram.com/mundoempaquescr/'>
                        <Figure.Image width={60} height={60} src={insta} />
                    </a>
                </Figure><p> </p>
            </Nav>

        </Navbar>


    );
}

export default Menu;
