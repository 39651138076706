import React, { Component } from 'react';


import Container from 'react-bootstrap/Container'
import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'


// IMPORTACION DE IMAGENES
import whats from '../Assets/img/whats.png'
import mail from '../Assets/img/mail.png'
import contactus from '../Assets/img/contactus.jpg'
import insta from '../Assets/img/insta.png'
import face from '../Assets/img/face.png'


class Contact extends Component {

    render() {
        return (
            <Container className=' robotomono font-weight-bold'>

                <Row className='py-5'>

                    <Col className='py-4' >

                        <Row >
                            
                            <Col xs={6} md={6}>
                                <Image className='mw-100 mr-5 mb-5' alt="Max-width 100%" src={contactus}></Image>
                            </Col>

                            <Col className=" justify-content-md-center robotomono font-weight-bold" bg="white" text="cark" border="white" >






                                <Col className='ml-n2' md="auto"><Figure >
                                    <Figure.Image className='' width={40} height={40} alt="10x18" src={mail} />
                                </Figure> <mail >info@mundoempaques.com</mail>
                                </Col>



                                <Col className='ml-n2' md="auto" ><a href="https://wa.me/50684033187"> <Figure >
                                    <Figure.Image className='' width={45} height={45} alt="10x18" src={whats} />
                                </Figure >  <mail className='text-dark ml-n1' > 8403-3187 </mail></a></Col>


                                <Col className='ml-n2' md="auto" ><a href="https://wa.me/50683409819"> <Figure >
                                    <Figure.Image className='' width={45} height={45} alt="10x18" src={whats} />
                                </Figure >  <mail className='text-dark ml-n1' > 8340-9819 </mail></a></Col>


                                <Col className='ml-n3' md="auto" ><a href="https://www.instagram.com/mundoempaquescr/"> <Figure >
                                    <Figure.Image className='' width={55} height={55} alt="10x18" src={insta} />
                                </Figure >  <mail className='text-dark ml-n1' > instagram </mail></a></Col>

                                <Col className='ml-n3' md="auto" ><a href="https://www.facebook.com/mundoempques"> <Figure >
                                    <Figure.Image className='' width={55} height={55} alt="10x18" src={face} />
                                </Figure >  <mail className='text-dark ml-n1' > facebook </mail></a></Col>



                            </Col>
                        </Row>


                    </Col>


                </Row>

                <Row className='p-5'>

                </Row>


            </Container >
        );
    }
}

export default Contact;


