import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button'


import Otros1 from '../Assets/img/Otros/Picture1.png'
import Otros2 from '../Assets/img/Otros/Picture2.png'
import Otros3 from '../Assets/img/Otros/Picture3.png'
import Otros4 from '../Assets/img/Otros/Picture4.png'
import Otros5 from '../Assets/img/Otros/Picture5.png'
import Otros6 from '../Assets/img/Otros/Picture6.png'
import Otros7 from '../Assets/img/Otros/Picture7.png'


class Vasos extends Component { 

    render() {
        return (
            <Container >
                            <Row>
<Col></Col>
<Col className='robotomono font-weight-bold pl-5 ml-5'><h3 className='robotomono font-weight-bold ml-5' > OTROS</h3></Col>
<Col></Col>


</Row>

            <Row className='montserrat font-weight-bold' >

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect border-0' src={Otros1}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Otros2}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4} >
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Otros3}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Otros4} />
                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className=' zoom hovereffect' src={Otros5} />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Otros6}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Otros7}  />

                    </Button>
                </Col>


            </Row>
        </Container >




        );
    }
}

export default Vasos;


