import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button'


import Batidos1 from '../Assets/img/Batidos/Picture1.png'
import Batidos2 from '../Assets/img/Batidos/Picture2.png'
import Batidos3 from '../Assets/img/Batidos/Picture3.png'
import Batidos4 from '../Assets/img/Batidos/Picture4.png'
import Batidos5 from '../Assets/img/Batidos/Picture5.png'
import Batidos6 from '../Assets/img/Batidos/Picture6.png'
import Batidos7 from '../Assets/img/Batidos/Picture7.png'
import Batidos8 from '../Assets/img/Batidos/Picture8.png'
import Batidos9 from '../Assets/img/Batidos/Picture9.png'
import Batidos10 from '../Assets/img/Batidos/Picture10.png'
import Batidos11 from '../Assets/img/Batidos/Picture11.png'




class Batidos extends Component {

    render() {
        return (
            <Container >
                            <Row>
<Col></Col>
<Col className='robotomono font-weight-bold pl-5 ml-5'><h3 className='robotomono font-weight-bold ml-5' > Batidos</h3></Col>
<Col></Col>


</Row>

            <Row className='montserrat font-weight-bold' >

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect border-0' src={Batidos1}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos2}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4} >
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos3}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos4} />
                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className=' zoom hovereffect' src={Batidos5} />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos6}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos7}  />

                    </Button>
                </Col>
                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos8}  />

                    </Button>
                </Col>
                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos9}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos10}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Batidos11}  />

                    </Button>
                </Col>

            </Row>
        </Container >




        );
    }
}

export default Batidos;


