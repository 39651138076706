import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button'


import Bowl1 from '../Assets/img/Bowl/Picture1.png'
import Bowl2 from '../Assets/img/Bowl/Picture2.png'
import Bowl3 from '../Assets/img/Bowl/Picture3.png'
import Bowl4 from '../Assets/img/Bowl/Picture4.png'
import Bowl5 from '../Assets/img/Bowl/Picture5.png'
import Bowl6 from '../Assets/img/Bowl/Picture6.png'
import Bowl7 from '../Assets/img/Bowl/Picture7.png'
import Bowl8 from '../Assets/img/Bowl/Picture8.png'
import Bowl9 from '../Assets/img/Bowl/Picture9.png'
import Bowl10 from '../Assets/img/Bowl/Picture10.png'
import Bowl11 from '../Assets/img/Bowl/Picture11.png'
import Bowl12 from '../Assets/img/Bowl/Picture12.png'
import Bowl13 from '../Assets/img/Bowl/Picture13.png'
import Bowl14 from '../Assets/img/Bowl/Picture14.png'
import Bowl15 from '../Assets/img/Bowl/Picture15.png'
import Bowl16 from '../Assets/img/Bowl/Picture16.png'



class Bowls extends Component {

    render() {
        return (
            <Container >
                            <Row>
<Col></Col>
<Col className='robotomono font-weight-bold pl-5 ml-5'><h3 className='robotomono font-weight-bold ml-5' > Bowls</h3></Col>
<Col></Col>


</Row>

            <Row className='montserrat font-weight-bold' >

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect border-0' src={Bowl1}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl2}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4} >
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl3}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl4} />
                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className=' zoom hovereffect' src={Bowl5} />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl6}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl7}  />

                    </Button>
                </Col>
                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl8}  />

                    </Button>
                </Col>
                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl9}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl10}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl11}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl12}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl13}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl14}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl15}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Bowl16}  />

                    </Button>
                </Col>

            </Row>
        </Container >




        );
    }
}

export default Bowls;


