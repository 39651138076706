import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button'


import Vasos1 from '../Assets/img/Vasos/Picture1.png'
import Vasos2 from '../Assets/img/Vasos/Picture2.png'
import Vasos3 from '../Assets/img/Vasos/Picture3.png'
import Vasos4 from '../Assets/img/Vasos/Picture4.png'
import Vasos5 from '../Assets/img/Vasos/Picture5.png'
import Vasos6 from '../Assets/img/Vasos/Picture6.png'



class Vasos extends Component { 

    render() {
        return (
            <Container >
                            <Row>
<Col></Col>
<Col className='robotomono font-weight-bold pl-5 ml-5'><h3 className='robotomono font-weight-bold ml-5' > VASOS</h3></Col>
<Col></Col>


</Row>

            <Row className='montserrat font-weight-bold' >

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect border-0' src={Vasos1}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Vasos2}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4} >
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Vasos3}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Vasos4} />
                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className=' zoom hovereffect' src={Vasos5} />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Vasos6}  />

                    </Button>
                </Col>

            </Row>
        </Container >




        );
    }
}

export default Vasos;


