import React, { Component } from 'react';

import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'


import nosotros from '../Assets/img/nosotros.jpg'







class Nosotros extends Component {

    render() {
        return (

            <Container>
                <Row className='pt-5 mt-5 '></Row>
                <Row>


                    <Col >

                        <Image className='mw-100 mt-3' alt="Max-width 100%" src={nosotros}></Image>

                    </Col>

                    <Col className='montserrat' sm={8}>
                        <Alert variant="ligth">

                            <p>
                            Mundo empaques es una compañia costarricense, fundada en 2012. Desde su nacimiento 
                            su vision siempre ha sido ser la empresa lider del mercado de empaques y productos 
                            desechables para alimentos en toda Costa Rica con productos innovadores y amigables 
                            con el ambiente, con el afan de satisfacer las necesidades mas exigentes de cada cliente 
                            de forma personalizada. Nuestra filosofia es siempre brindar un servicio personalizado de entrega, 
                            calidad y garantia del producto y del servicio, con opciones nuevas que complazcan un mercado amplio 
                            y exigente.
                            </p>
                            <p>  Nuestra mayor satisfaccion es poder ofrecer el servicio puerta a puerta. 
                                Nos caracterizamos por satisfacer su necesidad hasta el lugar donde lo necesite. 
                                Queremos ser aliado de su proyecto, por lo que nos enfocamos en entregar nuestros 
                                productos con calidad y en el menor tiempo posible

                            </p>


                            <hr />
                            <p className="mb-0">

                            </p>
                        </Alert>
                    </Col>

                </Row>

            </Container >
        );
    }
}

export default Nosotros;
