import React from 'react';
import { Image } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

// importacion de los efectos de las cards, se modifica el CSS para darle elpadding 
import '../CSS/cardsefects.css';

// importacion de toda la libreria de imagenes 


import Vasos from '../Assets/img/Vasos.png'
import Batidos from '../Assets/img/Batidos.png'
import Bowls from '../Assets/img/Bowls.png'
import Empaques from '../Assets/img/Empaques.png'
import Cajas from '../Assets/img/Cajas.png'
import Bolsas from '../Assets/img/Bolsas.png'
import Otros from '../Assets/img/Otros.png'


const Cardfilm = (props) => {
    return (

        <Container >

            <Row className='montserrat font-weight-bold' >

            <Col className='hovereffect' xs={6} md={4}>
                    <Button href='../pages/Empaques' variant="outline-link">
                        <Image className='hovereffect' src={Empaques} alt="Empaques" />
                        <Card.ImgOverlay className='mt-3 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n3'><h4>BIODEGRADABLE</h4></Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Button>
                </Col>

                <Col className='hovereffect' xs={6} md={4} >
                    <Button href='../pages/Bowls' variant="outline-link">
                        <Image className='hovereffect' src={Bowls} alt="Bowls" />
                        <Card.ImgOverlay className='mt-3 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n3'><h4>BOWLS</h4></Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Button>
                </Col>

                <Col className='hovereffect' xs={6} md={4}>
                    <Button href='../pages/Cajas' variant="outline-link">
                        <Image className='hovereffect' src={Cajas} alt="Cajas" />
                        <Card.ImgOverlay className='mt-3 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n3'><h4>CAJAS</h4></Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Button>
                </Col>
                
                <Col className='hovereffect' xs={6} md={4}>
                    <Button href='../pages/Vasos' variant="outline-link">
                        <Image className='hovereffect border-0' src={Vasos} alt="Vasos" />
                        <Card.ImgOverlay className='mt-n1 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n3'><h4>VASOS</h4></Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Button>
                </Col>


                <Col className='hovereffect' xs={6} md={4}>
                    <Button href='../pages/Batidos' variant="outline-link">
                        <Image className='hovereffect' src={Batidos} alt="Batidos" />
                        <Card.ImgOverlay className='mt-3 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n4'><h5>BATIDOS</h5></Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Button>
                </Col>






                <Col className='hovereffect' xs={6} md={4}>
                    <Button href='../pages/Bolsas' variant="outline-link">
                        <Image className='hovereffect' src={Bolsas} alt="Bolsas" />
                        <Card.ImgOverlay className='mt-3 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n3'><h4>BOLSAS</h4></Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Button>
                </Col>

                <Col className='hovereffect' xs={6} md={4}>
                    <Button href='../pages/Otros' variant="outline-link">
                        <Image className='hovereffect' src={Otros} alt="Otros" />
                        <Card.ImgOverlay className='mt-3 align-items-center justify-content-center'>
                            <Row>
                                <Col className=' mb-n3'><h4>OTROS</h4></Col>
                            </Row>

                        </Card.ImgOverlay>
                    </Button>
                </Col>



            </Row>
        </Container >





    );
};

export default Cardfilm;