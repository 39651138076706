import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button'


import Cajas1 from '../Assets/img/Cajas/Picture1.png'
import Cajas2 from '../Assets/img/Cajas/Picture2.png'
import Cajas3 from '../Assets/img/Cajas/Picture3.png'
import Cajas4 from '../Assets/img/Cajas/Picture4.png'
import Cajas5 from '../Assets/img/Cajas/Picture5.png'
import Cajas6 from '../Assets/img/Cajas/Picture6.png'
import Cajas7 from '../Assets/img/Cajas/Picture7.png'
import Cajas8 from '../Assets/img/Cajas/Picture8.png'
import Cajas9 from '../Assets/img/Cajas/Picture9.png'




class Cajas extends Component {

    render() {
        return (
            <Container >
                            <Row>
<Col></Col>
<Col className='robotomono font-weight-bold pl-5 ml-5'><h3 className='robotomono font-weight-bold ml-5' > CAJAS</h3></Col>
<Col></Col>


</Row>

            <Row className='montserrat font-weight-bold' >

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect border-0' src={Cajas1}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas2}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4} >
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas3}  />

                    </Button>
                </Col>

                <Col xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas4} />
                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className=' zoom hovereffect' src={Cajas5} />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas6}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas7}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas8}  />

                    </Button>
                </Col>

                <Col  xs={6} md={4}>
                    <Button href='' variant="outline-link">
                        <Image className='zoom hovereffect' src={Cajas9}  />

                    </Button>
                </Col>

            </Row>
        </Container >




        );
    }
}

export default Cajas;


