import React from 'react';

import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


// IMPORTACION DE IMAGENES

import whats from '../Assets/img/whats.png'
import mail from '../Assets/img/mail.png'



function Footer() {
    return (

        <Card className="justify-content-md-center" bg="white" text="cark" border="white" >

            <Card.Body className='robotomono font-weight-bold'>
                <Card.Text className='row align-items-center justify-content-center'>
                </Card.Text>


                <Row className="mt-5 justify-content-md-center">
                    <Col md="auto"><Figure >
                        <Figure.Image className='m-1' width={30} height={30} alt="10x18" src={mail} />
                    </Figure> <mail >info@mundoempaques.com</mail>
                    </Col>


                    <Col className='ml-n2' md="auto" > <Figure >
                        <Figure.Image className='' width={35} height={35} alt="10x18" src={whats} />
                    </Figure ><mail className='ml-n1' > 8403-3187 / 8340-9819 </mail></Col>
                </Row>



            </Card.Body>
        </Card>


    );
}

export default Footer;
