import React from 'react';
import { Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './CSS/fonts.css';
import Menu from './Components/menu';
import Footer from './Components/footer';

import Films from './pages/Films';
import Nosotros from './pages/Nosotros';
import Contact from './pages/Contact';
import Otros from './pages/Otros'
import Bolsas from './pages/Bolsas'
import Cajas from './pages/Cajas'
import Empaques from './pages/Empaques';
import Bowls from './pages/Bowls';
import Batidos from './pages/Batidos';
import Vasos from './pages/Vasos';







function App() {
  return (

    <div>
      <Menu />

      <section>

        <Switch>
          <Route path='/pages/Nosotros' component={Nosotros} />
          <Route exact path='/' component={Films} />
          <Route path='/pages/Contact' component={Contact} />
           
          <Route path='/pages/Otros' component={Otros} />
          <Route path='/pages/Bolsas' component={Bolsas} />
          <Route path='/pages/Cajas' component={Cajas} /> 
          <Route path='/pages/Empaques' component={Empaques} />
          <Route path='/pages/Bowls' component={Bowls} /> 
          <Route path='/pages/Batidos' component={Batidos} />
          <Route path='/pages/Vasos' component={Vasos} /> 
        </Switch>

      </section>

      <Footer />
    </div>



  );
}

export default App;
